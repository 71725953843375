import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import SocialProfile from "../../components/SocialProfile/SocialProfile"
import { IoLogoFacebook, IoLogoInstagram, IoLogoYoutube } from "react-icons/io"
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style"

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: "https://www.facebook.com/Lovely-Heart-Life-108400953883726/",
    tooltip: "Facebook",
  },
  {
    icon: <IoLogoInstagram />,
    url: "https://www.instagram.com/lovelyheartlife/",
    tooltip: "Instagram",
  },
  {
    icon: <IoLogoYoutube />,
    url: "https://www.youtube.com/channel/UCpnlL2_MZoZma0L45r9LfIg",
    tooltip: "Youtube",
  },
]

interface AboutProps { }

const About: React.FunctionComponent<AboutProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Lovely Heart Life</h2>
        <p>
          I created Lovely Heart Life originally as a blog to keep track of fun family memories, but it has quickly evolved into something even more special. I have always loved writing and I love that I now have an outlet for that passion. Now you will find other content such as educational activities for your littles and kid friendly recipes that me and my family love!
      </p>
        <p>
          Lovely Heart Life is more than a blog, it’s also a shop! Coming in November, you will be able to purchase the Lovely Heart Learning Curriculum. This curriculum is for children ages 2-5 and will be available each month with a new theme and letter to learn! I am so excited about this project. I graduated with a Bachelor of Science in Elementary Education with an emphasis on Early Childhood Education and one of my favorite parts of school and my student teaching was creating and implementing lesson plans. If you’ve been wanting to start doing more educational projects and activities with your child, then you will want to purchase this! Every month will have printable activities, fun recipes, and full lesson plans written out for you. It’s all about learning through play!
        </p>
        <p>
          I can’t wait to share all of this fun and special content with you all!
        </p>
      </AboutPageTitle>

      <SocialProfiles>
        <SocialProfile items={SocialLinks} />
      </SocialProfiles>

      <AboutImage>
        <Image
          style={{ maxWidth: 500, margin: "0 auto" }}
          fluid={Data.avatar.childImageSharp.fluid}
          alt="author"
        />
      </AboutImage>


      {/* <AboutDetails>
        <h2>More about us!</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
          rhoncus faucibus enim sit amet laoreet. Etiam eget purus et turpis
          volutpat eleifend eu et ipsum. Pellentesque ultrices facilisis eros,
          id vestibulum tellus bibendum non. Aliquam suscipit faucibus eros, vel
          efficitur arcu fermentum sed.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
          rhoncus faucibus enim sit amet laoreet. Etiam eget purus et turpis
          volutpat eleifend eu et ipsum. Pellentesque ultrices facilisis eros,
          id vestibulum tellus bibendum non. Aliquam suscipit faucibus eros, vel
          efficitur arcu fermentum sed. Mauris vel ornare ligula. Vivamus
          sagittis interdum purus, eu mollis arcu blandit sit amet. Suspendisse
          placerat metus vitae ligula semper, sit amet euismod metus vestibulum.
          Sed auctor aliquam ipsum. Mauris tristique consequat neque, vitae
          convallis dui viverra quis. In leo orci, congue vitae commodo vitae,
          sagittis in magna. Quisque felis ligula, porta in condimentum quis,
          tincidunt id purus. Vestibulum elementum libero sapien, eu scelerisque
          nibh accumsan nec. Proin laoreet tristique sem non gravida. In ut
          placerat augue. Donec accumsan massa vel felis lobortis consequat ut
          ac tortor. Nunc cursus nunc in diam tincidunt, eget porta velit
          iaculis. Fusce nec nisl vulputate, pellentesque sem in, suscipit nisl.
          Aliquam pharetra dui nunc.
        </p>

      </AboutDetails> */}
    </AboutWrapper>
  )
}

export default About
